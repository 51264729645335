import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Chip from '../chip/Chip'
import { FavoriteEvent } from '../favorite-event/favorite-event'
import { getIconName } from '../icon/icon.helper'
import { Icon } from '../icon/icon'
import LoginModal from '../login-modal/login-modal'
import Text from '../text/text'
import { NavLink } from '~/components/internal-nav-link/Internal-nav-link'
import { getImgSrc } from '~/utils/image'
import { cn } from '~/utils/misc'
import { useOptionalUser } from '~/utils/user'
import type { EventIndex } from '~/types/eventIndex'
import type { AdminEvent } from '~/types/adminEvent'

export default function EventList({
	events,
	showFavorite = false,
	size = 'md',
	aspectRatio = 'video',
}: {
	events: EventIndex[] | AdminEvent[]
	showFavorite?: boolean
	size?: 'sm' | 'md' | 'lg'
	aspectRatio?: 'square' | 'video'
}) {
	const [showLoginModal, setShowLoginModal] = useState(false)
	const user = useOptionalUser()
	const { t } = useTranslation()
	const toggleModal = () => setShowLoginModal(prev => !prev)
	return (
		<>
			{showLoginModal && (
				<LoginModal
					text={t('misc_login_to_add_favorite')}
					toggleLoginModal={toggleModal}
					initialPage={0}
				/>
			)}
			<div
				className={cn(
					'grid grid-cols-12 gap-4 md:gap-6',
					size === 'sm' && 'xl:grid-cols-10',
				)}
			>
				{events.map(x => {
					const sizeClass =
						size === 'sm'
							? 'sm:col-span-6 lg:col-span-3 xl:col-span-2'
							: size === 'md'
								? 'sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3'
								: 'md:col-span-6 xl:col-span-4'
					return (
						<div
							key={x.dateId}
							className={cn(
								'relative col-span-12 transition duration-200 hover:opacity-80',
								sizeClass,
							)}
						>
							{user && showFavorite ? (
								<div className="absolute right-0 top-1 z-[1] transition duration-200 hover:scale-105">
									<FavoriteEvent
										favoredByUser={
											user?.favorites.some(e => e.id === x.dateId) ?? false
										}
										eventDateId={x.dateId}
										eventName={x.title}
									/>
								</div>
							) : (
								showFavorite && (
									<div className="absolute right-4 top-3 z-[1] transition duration-200 hover:scale-105">
										<div>
											<button
												aria-label={t('action_add_as_favorite', {
													title: x.title,
												})}
												className="items-center justify-center text-foreground/40 dark:text-background/50"
												onClick={toggleModal}
											>
												<Icon
													className="stoke-1 scale-125 stroke-white max-md:scale-150"
													name="heart-2"
													size="md"
												/>
											</button>
										</div>
									</div>
								)
							)}
							<NavLink
								className="flex flex-col rounded-xl"
								to={x.url}
								state={{ from: 'internal' }}
								viewTransition
							>
								{({ isTransitioning }) => (
									<>
										<div
											className="relative w-full select-none"
											style={
												isTransitioning
													? {
															viewTransitionName: 'image-expand',
														}
													: undefined
											}
										>
											<img
												src={getImgSrc(x?.imageId, 'event')}
												alt={x?.title}
												className={cn(
													'w-full rounded-t-xl object-cover',
													aspectRatio === 'square'
														? 'aspect-square'
														: 'aspect-video',
												)}
												loading="lazy"
											/>

											<Chip
												className="!absolute left-0 top-0 !rounded-bl-none !rounded-tr-none"
												text={
													<div className="flex items-center">
														<Icon
															name={getIconName(x.category.icon)}
															size="xs"
															className="mr-1"
														/>
														{x.category.name}
													</div>
												}
												color={x.category.color}
											/>
											<div className="absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent"></div>
											<div
												className="absolute bottom-2 left-2 right-2"
												style={
													isTransitioning
														? {
																viewTransitionName: 'title-expand',
															}
														: undefined
												}
											>
												<Text
													as="h4"
													className="truncate !text-h3 !leading-6 text-white drop-shadow-xl"
												>
													{x.title}
												</Text>
											</div>
										</div>

										<div className="rounded-b-xl border-b border-l border-r border-border bg-card p-2">
											{x.location && (
												<Text className="truncate font-semibold">
													{x.location}
												</Text>
											)}
											{x.dateTime && (
												<span className="flex justify-between truncate align-middle">
													<Text as="sm">{x.dateTime}</Text>
													{x.state === 'Cancelled' ? (
														<Chip
															text={t('misc_cancelled')}
															size="xs"
															color="amber"
														/>
													) : x.state === 'SoldOut' ? (
														<Chip
															text={t('misc_sold_out')}
															size="xs"
															color="amber"
														/>
													) : x.newDate ? (
														<Chip
															text={t('misc_new_date')}
															size="xs"
															color="indigo"
														/>
													) : undefined}
													{x.datesCount && x.datesCount > 1 && (
														<b> ({x.datesCount}st)</b>
													)}
												</span>
											)}
										</div>
									</>
								)}
							</NavLink>
						</div>
					)
				})}
			</div>
		</>
	)
}
